export const notification = {
  send: {
    url: "admin/notification/create/post",
    method: "POST",
    id: null,
    data: null,
  },
  getAll: {
    url: "admin/notification/get",
    method: "GET",
    id: null,
    params: null,
  },
};

<template>
  <b-modal id="fcm-modal" hide-footer>
    <div v-if="selectedDriver">
      Send message to {{ selectedDriver.user_type }}<br />
      Email : {{ selectedDriver.email }}
    </div>
    <div v-else>SEND MESSAGE TO ALL</div>
    <FormulateForm
      name="messageBody"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="sending"> SENDING...</span><span v-else>SEND</span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { notification } from "@/config/api/notification";
export default {
  props: ["selectedDriver"],
  data() {
    return {
      values: {},
      sending: false,
      schema: [
        {
          type: "text",
          name: "title",
          label: "Title",
          validation: "required",
        },
        {
          type: "textarea",
          name: "description",
          label: "Message",
          validation: "required",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      const api = notification.send;
      api.data = {
        ...data,
        user_id: this.selectedDriver ? this.selectedDriver._id : null,
      };
      this.sending = true;
      this.generateAPI(api)
        .then(() => {
          this.$emit('sendSuccess')
          this.$formulate.reset("messageBody");
          this.$bvToast.toast("Message send successfully", {
            title: "Message",
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Message is not send!", {
            title: "Message",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>


<template>
  <Layout>
    <b-button @click="openFCMModel" style="display: flex">
      <span style="margin-right: 4px"> Send FCM</span>
      <i class="ri-send-plane-2-line"></i
    ></b-button>
    <FcmModal v-on:sendSuccess="closeModal" />
    <History :reload="reload" v-on:dataLoaded="reload = false" />
  </Layout>
</template>

<script>
import FcmModal from "@/components/notification/modal";
import History from "@/components/notification/history";
import Layout from "../../layouts/main";
export default {
  components: {
    Layout,
    FcmModal,
    History,
  },
  data() {
    return {
      reload: false,
    };
  },
  methods: {
    openFCMModel() {
      this.$bvModal.show("fcm-modal");
    },
    closeModal() {
      this.$bvModal.hide("fcm-modal");
      this.reload = true;
    },
  },
};
</script>

<style>
</style>
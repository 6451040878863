<template>
  <div class="row mt-2">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="notificationData"
              :fields="fields"
              responsive="sm"
              :busy="isBusy"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #cell(createdAt)="row">
                {{ moment(row.value).format("lll") }}
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                     @change="updatePage"

                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notification } from "@/config/api/notification";

export default {
  props: ["reload"],
  data() {
    return {
      notificationData: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      fields: [
        { key: "title", sortable: false },
        { key: "description", sortable: false },
        { key: "createdAt", sortable: false },
      ],
    };
  },
  created() {
    this.getAllNotificaton();
  },
  computed: {
    rows() {
      return this.notificationData.length;
    },
  },
  watch: {
    reload(val) {
      if (val) {
        this.getAllNotificaton();
      }
    },
  },

  methods: {
    updatePage(page) {
      console.log("Test")
      this.getAllNotificaton(page);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllNotificaton(page = 1) {
      this.isBusy = true;
      const api = notification.getAll;
      api.params = {page: page}
      this.generateAPI(api)
        .then((res) => {
          this.notificationData = res.data.notification;
          this.totalRows = res.data.total_no_of_documents
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.isBusy = false;
          this.$emit("dataLoaded");
        });
    },
  },
};
</script>
